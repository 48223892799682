import Button from "../../../../components/forms/Button";
import RadioGroup from "../../../../components/forms/RadioGroup";
import { RECIPIENT_OPTIONS } from "../../constants";
import "./index.scss";
export default props => {
    const { params } = props;
    const isDisabled = !params.recipient;


    return (
        <div className="personalize-step">
            <h3>Who will be telling stories on Storyworth?</h3>
            <RadioGroup options={RECIPIENT_OPTIONS}
                onChange={e => props.onChange('recipient', e)} value={params.recipient}
            />
            <br/><br/><br/>
            <Button disabled={isDisabled} onClick={props.onSubmit}>CONTINUE</Button>
        </div>
    )
}