import { Collapse } from "antd";
import { isMobile } from "../../../../services/clientService";
// const { Panel } = Collapse;
import "./index.scss";
const TITLE_BY_STEP = {
    0: "Storyworth Subscription",
    1: "One year Storyworth subscription"
}
export default ({ params = {} }) => {
    const { totalAmount, numOfBooks, step, recipient } = params;
    const shouldShowDisclaimer = !!recipient;
    const isMobileLayout = isMobile()

    const sidebarCompoennt = (
        <div className="preview-sidebar">
            <img src="https://static.storyworth.com/images/dist/purchase_side_book.png?v=b781dab8054f2ff9dd3598c366d3bd8f3b3ee63e70b591eb9a72f2fd5fcc539f310a9d19f8b944d2060cb7bfb62c9ffc8e15a4a7631ff97a0d241f99b87c3f77" />
            <div className="title">{TITLE_BY_STEP[step]}</div>
            <div className="price">Total: <b>${totalAmount}</b></div>
            <div className="includes">INCLUDES</div>
            <br />
            <ul className="included-items">
                <li>Weekly story prompts</li>
                <li>{`${numOfBooks} black & white interior hardcover books`}</li>
            </ul>
            {shouldShowDisclaimer && <div className="disclaimer">
                Your subscription will renew annually. You can cancel your renewal at any time.
            </div>}
        </div>
    )

    return isMobileLayout ? <Collapse items={[
        {
            key: '1',
            label: (
                <div className="preview-sidebar-mobile-wrapper">
                    <div className="title">
                        Storyworth Subscription
                    </div>
                    <div className="amount">
                        {`$${totalAmount}`}
                    </div>
                </div>
            ),
            children: sidebarCompoennt
        }
    ]} />: sidebarCompoennt
}