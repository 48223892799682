import { Radio, Space } from "antd"
import "./index.scss";
export default props => {
    const { value } = props;
    return (
        <Radio.Group className="radio-group" value={value}>
            <Space direction="vertical">
                {props.options.map(option =>
                    <div className="radio-container" onClick={e => props.onChange(option.value)}>
                        <Radio className="radio-item"
                            value={option.value}>{option.label}</Radio>
                    </div>)}
            </Space>
        </Radio.Group>
    )
}