import Input from '../../../../components/forms/Input';
import Button from '../../../../components/forms/Button';
import { isEmailValid } from '../../../../services/textService';
import { postRequest } from '../../../../services/requestService';
import { useState } from 'react';
import { Alert } from 'antd';
import "./index.scss";

export default props => {
    const { loading, onChange, params, setLoading } = props;
    const [errorMessage, setErrorMessage] = useState('')
    const isDisabled = params.step === 0 && !isEmailValid(params.email)
    const onEmailSubmit = async () => {
        if (!isEmailValid(params.email)) {
            setErrorMessage("Please fill in your email.")
            return;
        }
        setLoading(true)
        setTimeout(async () => {
            await postRequest('auth/email-login', { email: params.email });
            onChange('step', 1);
            setLoading(false)
        }, 1000)
    }
    return (
        <div className="email-input-step">
            <div style={{textAlign: 'center'}}>LOGO</div>
            <h1>Sign in to MyMemories</h1>
            <Input placeholder="name@email.com" autofill="off"
                value={params.email} onChange={e => {
                    onChange('email', e.target.value)
                    if (isEmailValid(e.target.value)) {
                        setErrorMessage("")
                    }
                }}
            />
            <div className="error-message">{errorMessage}</div>
            <br /><br />
            <Button
                loading={loading} onClick={onEmailSubmit}>Continue</Button>
            <br /><br />
            <Alert description={`We'll email you a magic code for a password-free sign in.`} />
            <br /><br />

            <div className="links">
                <a className="link-item" href="/privacy" target="_blank" >Privacy Policy</a>
                <a className="link-item" href="/terms" target="_blank" >Terms of Use</a>
            </div>
        </div>
    )
}