import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { Spin } from 'antd';
import { getUserData } from '../store/reducers/Auth/actions';
import { getAuthToken } from '../services/authService';
import { buildLoginPageUrl } from '../services/urlBuilderService';

/**
 * This component wraps public pages with the following behaviour:
 * 1. If the user doesn't have a cookie --> show the page AS-IS
 * 2. If the user has a cookie --> try to fetch the user details and push them to redux
 * 3. If the user has auth cookie & we failed to fetch the token --> do nothing
 */
const PublicRoute = (props) => {
  const {
    component: Component,
    path,
    sidebar,
    history,
    pageName,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const [isInit, setIsInit] = useState(false)
  useEffect(() => {
    const authToken = getAuthToken();
    if (!userDetails && authToken) {
      dispatch(getUserData((isAuthSuccess) => {
        if (isAuthSuccess) {
          setIsInit(true)
        }
        else {
          dispatch(push(buildLoginPageUrl()))
        }
        
      }))
    }
    // There's no auth token & the user is not logged in
    else if (!userDetails) {
      dispatch(push(buildLoginPageUrl()))
    }
  }, []);

  const render = () =>
     isInit && userDetails ?  <Component
  {...props}
  pageName={pageName}
/> : <Spin />

  return <Route path={path} render={render} {...rest} />;
};

export default PublicRoute;
