import Button from '../../../../components/forms/Button';
import "./index.scss";
import { IoPersonOutline } from "@react-icons/all-files/io5/IoPersonOutline";

const DashboardActions = (props) => {
    const { userDetails } = props;
    if (!userDetails) return null;

    return (
        <div className="dashboard-actions">
            <div className="inner">
                <div className="breadcrumbs">
                    <div className="breadcrumb-item">ALL STORYTELLERS</div>
                    <div className="divider">{`>`}</div>
                    <div className="breadcrumb-item current">{`${userDetails.firstName} ${userDetails.lastName}`}</div>
                </div>
                <br />
                <div className="user-name">{`${userDetails.firstName} ${userDetails.lastName}`}</div>
                <div className="manage-account-btn">
                    <IoPersonOutline />
                    <span>MANAGE ACCOUNT</span>
                </div>
                <div className="buttons">
                    <Button>EDIT COVER</Button>
                    <Button>PREVIEW BOOK</Button>
                    <Button>ORDER BOOK</Button>
                </div>
            </div>
        </div>
    )
}

export default DashboardActions