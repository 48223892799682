import { Button } from "antd"
import _ from 'lodash';
import "./index.scss";
export default props => {
    const inheritedParams = _.pick(props, ["onClick", "disabled", "loading"])
    return (
        <Button className={`button ${props.variant || ''}`}
        
        {...inheritedParams}
        >{props.children}</Button>
    )
}