import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
// Import your individual reducers here
// import exampleReducer from './exampleReducer';
import AuthReducer from './Auth';
const rootReducer = history => combineReducers({
  // Add your reducers here
  // example: exampleReducer,
  router: connectRouter(history),
  AuthReducer
});

export default rootReducer;