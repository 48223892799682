import Page from "../../components/layout/Page";
import { Spin } from 'antd';
import {  useEffect } from "react";
import "./index.scss";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { postRequest } from "../../services/requestService";
import { useDispatch } from "react-redux";
import { buildLoginPageUrl } from "../../services/urlBuilderService";
import {push} from 'connected-react-router';

export default props => {
    const { otpToken } = useParams();
    const dispatch = useDispatch()
    const validateOtpToken = async () => {
        try {
            await postRequest('auth/validate-magic-token', { otpToken });
            dispatch(push('/'))
        }
        catch (e) {
            dispatch(push('/login'))
        }
    }
    useEffect(() => {
            if (otpToken) {
                validateOtpToken();
            }
            else {
                dispatch(push(buildLoginPageUrl()))
            }
    }, [])

    return (
        <Page className="magic-link-page">
            <Spin size={60} style={{fontSize: 50}} />
        </Page>
    )
}