import { isDev } from "./clientService";


const getAppUrl = (isAbsolute = false) => {
    if (!isAbsolute) return "/";

    return isDev() ? "https://localhost:3000/" : "https://app.mymemoires.com/";
}

export const buildPasswordlessLoginPage = (isAbsolute, otpToken) => `${getAppUrl(isAbsolute)}magic-auth/${otpToken}`

export const buildLoginPageUrl = (isAbsolute) => `${getAppUrl(isAbsolute)}login`