import Cookies from 'js-cookie';
export const COOKIE_NAME_REDIRECT_URL = 'redirect_url';
const BASE_URL_BY_ENV = {
  development: 'http://localhost:3005',
  production: 'https://api.mymemoires.com',
};

export const isDev = () =>
  ['development', 'staging', null, undefined].includes(
    process.env.REACT_APP_HOST_ENV,
  );

export const getEnvName = () => process.env.REACT_APP_HOST_ENV || 'development';

export const BASE_URL = BASE_URL_BY_ENV[getEnvName()];

const isClientSide = () => typeof window !== 'undefined' && window.document;

if (isClientSide()) {
  window.URLSearchParams =
    window.URLSearchParams ||
    function (searchString) {
      const self = this;
      self.searchString = searchString;
      self.get = function (name) {
        const results = new RegExp(`[?&]${name}=([^&#]*)`).exec(
          self.searchString,
        );
        if (results == null) {
          return null;
        }
        return decodeURI(results[1]) || 0;
      };
    };

  const envName = getEnvName();
  if (envName !== 'production' && document.title[0] !== '[') {
    document.title = `[${envName}] ${document.title}`;
  }
}

export const isMobile = () => {
  try {
    if (!navigator) return false;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  } catch (e) {
    return false;
  }
};

export const isSafari = () =>
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1;

export const isIE = () => /MSIE|Trident|Edg/.test(navigator.userAgent);

export const getQueryParam = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get(name);
  return myParam || undefined;
};

export const removeCookie = (cookieName, domain) => {
  const cookieDomain = domain || process.env.DOMAIN_NAME || 'localhost'
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${cookieDomain}`;
};

export const getAndDeleteCookie = (name) => {
  const cookieValue = Cookies.get(name);
  if (cookieValue) {
    removeCookie(name, '.mymemories.com');
  }

  return cookieValue;
};

export const getRedirectUrl = (shouldRemoveCookie = true) => {
  const res = getCookie(COOKIE_NAME_REDIRECT_URL);
  shouldRemoveCookie && removeCookie(COOKIE_NAME_REDIRECT_URL);

  return res;
};

export const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
};

export const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

