import { Alert } from "antd";
import "./index.scss";

export default props => {
    const COLORS = {
        yellow: 'rgb(255 241 220)'
    }
    return (
        <Alert className="alert" message={props.children} style={{backgroundColor: COLORS.yellow}} />
    )
}