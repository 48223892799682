import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { Spin } from 'antd';
import { getUserData } from '../store/reducers/Auth/actions';
import { getAuthToken } from '../services/authService';

/**
 * This component wraps public pages with the following behaviour:
 * 1. If the user doesn't have a cookie --> show the page AS-IS
 * 2. If the user has a cookie --> try to fetch the user details and push them to redux
 * 3. If the user has auth cookie & we failed to fetch the token --> do nothing
 */
const PublicRoute = (props) => {
  const {
    component: Component,
    path,
    sidebar,
    isInit,
    history,
    pageName,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);

  useEffect(() => {
    const authToken = getAuthToken();
    if (!userDetails && authToken) {
      dispatch(getUserData())
    }
  }, []);

  const render = () =>
      <Component
        {...props}
        pageName={pageName}
      />

  return <Route path={path} render={render} {...rest} />;
};

export default PublicRoute;
