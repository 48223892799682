import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Purchase from './pages/Purchase/index'; // Example component
import { Spin } from 'antd';
import LayoutComponent from './components/layout/LayoutComponent';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
import LoginPage from './pages/LoginPage';
import MagicLinkPage from './pages/MagicLinkPage';
import PublicRoute from './utils/PublicRoute';
import PrivateRoute from './utils/PrivateRoute';
import DashboardPage from './pages/DashboardPage';

function App() {
    return (
        <Suspense fallback={<Spin />}>
            <LayoutComponent>
                <Switch>
                    <Route path="/purchase" component={Purchase} />
                    <PublicRoute path="/login" component={LoginPage} />
                    <Route path="/magic-auth/:otpToken" component={MagicLinkPage} />
                    <PrivateRoute path="/" component={DashboardPage} />
                    {/* <Redirect path="/" to="/logi" /> */}
                    {/* Add more routes as needed */}
                </Switch>
            </LayoutComponent>
        </Suspense>
    );
}

export default App;