import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import createRootReducer from './reducers';

const initialState = {};
export const history = createBrowserHistory();

const middleware = [routerMiddleware(history), thunk];

export default () =>
  createStore(
    createRootReducer(history),
    initialState,
    composeWithDevTools(applyMiddleware(...middleware)),
  );
