export const STEP_PERSONALIZE = 'personalize';
export const STEP_DELIVERY = 'delivery';
export const STEP_BILLING = 'billing';



export const RECIPIENT_TYPE_ME = 'me';
export const RECIPIENT_TYPE_GIFT = 'gift'
export const RECIPIENT_OPTIONS = [{ label: 'I will', value: RECIPIENT_TYPE_ME }, { label: 'Someone else will', value: RECIPIENT_TYPE_GIFT }]

export const RECIPIENT_COPY = {
    [RECIPIENT_TYPE_ME]: {
        firstName: 'Your first name',
        lastName: 'Your last name',
        email: 'Your email address',
    },
    [RECIPIENT_TYPE_GIFT]: {
        firstName: 'Your gift recipient’s first name',
        lastName: 'Your gift recipient’s last name',
        email: 'Your gift recipient’s email'
    },
}