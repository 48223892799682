import Cookies from 'js-cookie';
export const COOKIE_NAME_AUTH_TOKEN = 'x-access-token';

export const setAuthToken = (token) => {
  Cookies.set(COOKIE_NAME_AUTH_TOKEN, token, { expires: 365 });
};

export const getAuthToken = () => Cookies.get(COOKIE_NAME_AUTH_TOKEN);

export const removeAuthToken = () => Cookies.remove(COOKIE_NAME_AUTH_TOKEN)
