import Page from "../../components/layout/Page";
import { Card } from 'antd';
import { useState, useRef } from "react";
import EmailInputStep from "./Components/EmailInputStep";
import SetCodeStep from "./Components/SetCodeStep";
import "./index.scss";
import CheckEmailStep from "./Components/CheckEmailStep";

export default props => {
    const [params, setParams] = useState({ step: 0, email: '', codeValue: undefined })
    const [loading, setLoading] = useState(false)

    const onChange = (key, value) => {
        setParams({ ...params, [key]: value })
    }

    return (
        <Page className="login-page">
            <Card className="form">

                {params.step === 0 && <EmailInputStep params={params} onChange={onChange}
                    setLoading={setLoading} loading={loading}
                />}
                {params.step === 1 && (
                    <>
                        <CheckEmailStep params={params} setParams={setParams} onChange={onChange}
                            setLoading={setLoading} loading={loading} />
                    </>
                )}
            </Card>
        </Page>
    )
}