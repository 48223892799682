import { Layout as AntLayout } from 'antd';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { initAnalytics } from '../../../services/analyticsService';
import "../../../styles/antd.scss";


import Header from '../Header';
import "./index.scss";

const LayoutComponent = (props) => {

    useEffect(() => {
        // initAnalytics()
    }, []);

    return (
        <AntLayout className="layout">
            <Header />
            <div className="layout-content">{props.children}</div>
        </AntLayout>
    );
};

export default LayoutComponent;
