import { getAuthToken } from '../../../services/authService';
import { getRequest } from '../../../services/requestService';
import * as types from './types';
export function setUserDetails(userDetails) {
  return {
    type: types.SET_USER_DETAILS,
    payload: { userDetails }
  }
}

/**
 * Fetch the user details and store them in Redux
 * This would work only if the user has JWT token stored in their cookie
 */
export const getUserData = (callback) => async dispatch => {
  const authToken = getAuthToken();
  if (!authToken) return;
  let res;
  try {
    res = await getRequest(
      'auth/get-user-data',
      {},
      {
        headers: {
          'x-access-token': getAuthToken(),
        },
      },
    );

    dispatch(setUserDetails(res.data))
  }
  catch (e) {

  }
  callback && callback(!!res?.data)
}