import { useState } from "react";
import "./index.scss";
import PreviewSidebar from "./Components/PreviewSidebar";
import PersonalizeStep from "./Components/PersonalizeStep";
import OrderStep from "./Components/OrderStep";
import { BASE_PRICE, BOOKS_PRICE_MAP } from "../../constants/pricing";
import { RECIPIENT_TYPE_ME, STEP_BILLING, STEP_DELIVERY, STEP_PERSONALIZE } from "./constants";
import GiftStep from "./Components/GiftStep";
export default props => {
  const [params, setParams] = useState({
    step: STEP_PERSONALIZE,
    numOfBooks: 1,
    totalAmount: BASE_PRICE,
    recipient: undefined,
    firstName: "",
    lastName: "",
    email: "",
    recipientFirstName: "",
    recipientLastName: "",
    recipientEmail: "",
    sendGiftAt: new Date(),
    senderName: "",
    senderMessage: "Hi, I’m giving you a subscription to Storyworth, so you can write and share your stories with me and the family."
  })

  const onChange = (key, value) => {
    const updatedParams = {...params, [key]: value}
    if (key === 'numOfBooks') {
      updatedParams.totalAmount = BASE_PRICE + BOOKS_PRICE_MAP[value];
    }
    setParams(updatedParams)
  }
  const onSubmit = () => {
    if (params.step === STEP_PERSONALIZE) {
      onChange('step', params.recipient === RECIPIENT_TYPE_ME ? STEP_BILLING : STEP_DELIVERY )
      return;
    }
    else if (params.step === STEP_DELIVERY) {
      onChange('step', STEP_BILLING);
    }
  }
  return (
    <div className="purchase-page">
      <div className="step-content">
        {params.step === STEP_PERSONALIZE && <PersonalizeStep onChange={onChange} params={params} onSubmit={onSubmit} />}
        {params.step === STEP_DELIVERY && <GiftStep onChange={onChange} params={params} onSubmit={onSubmit} />}
        {params.step === STEP_BILLING && <OrderStep onChange={onChange} params={params} onSubmit={onSubmit} />}
      </div>
      <div className="sidebar-wrapper">
          <PreviewSidebar params={params} />
      </div>
    </div>
  )
}