import dayjs from 'dayjs';

export const getFriendlyTime = (date, lowercase = false) => {
  if (!date) {
    return '';
  }
  const parsedDate = new Date(JSON.parse(JSON.stringify(new Date(date))));
  const delta = Math.round((+new Date() - parsedDate) / 1000);
  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  let fuzzy;

  if (delta < 30) {
    fuzzy = 'Just now.';
  } else if (delta < minute) {
    fuzzy = `${delta} sec ago`;
  } else if (delta < 2 * minute) {
    fuzzy = 'A minute ago';
  } else if (delta < hour) {
    fuzzy = `${Math.floor(delta / minute)} min ago`;
  } else if (Math.floor(delta / hour) === 1) {
    fuzzy = 'An hour ago';
  } else if (delta < day) {
    fuzzy = `${Math.floor(delta / hour)} hours ago`;
  } else if (delta < day * 2) {
    fuzzy = 'Yesterday';
  } else {
    fuzzy = dayjs(parsedDate).format('MM/DD/YYYY');
  }
  return lowercase ? fuzzy.toLowerCase() : fuzzy;
};

export const isEmailValid = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const capitalizeEveryWord = (str) =>
  str
    .split(/\s+/)
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase())
    .join(' ');

export const getDaysSince = (requestedDate) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

  return Math.round(Math.abs((requestedDate - new Date()) / oneDay));
};
