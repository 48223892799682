import "./index.scss";
export default props => {
    return (
        <header className="header">
            <div className="inner">
                <div className="logo">
                    <img src="https://static.storyworth.com/images/dist/storyworth-logo-horizontal.png?v=f7a17a15c1418db49b2be96d78d85b9ad1ffc550ac5d34b584b0acedaa0445bd127cddd7a40d715472b738b6fe1deff67c4f48e92d06ffc806be7c1af7d04fe4" />
                </div>
            </div>
        </header>
    )
}