import Button from "../../../../components/forms/Button";
import { BUTTON_VARIANT_SECONDARY } from "../../../../components/forms/Button/constants";
import { getFriendlyTime } from "../../../../services/textService";
import "./index.scss";
import {FaArrowsAltV} from '@react-icons/all-files/fa/FaArrowsAltV'
const StoryList = ({ userDetails }) => {
    const { books } = userDetails;
    if (!books?.length) return null;
    
    const {questions} = books[0];
    const editAnswer = (question) => {
        console.log(question)
    }
    return (
        <div className="story-list">
            <h3>Your stories</h3>

            <div className="stories">
                {questions.map((question, idx) => {
                    const didUserAnswerQuestion = question.answer.filter(singleAnswer => !!singleAnswer?.text?.length).length > 0
                    return (
                        <div className="story-item" key={idx}>
                            <div className="index-and-sorter">
                                <div className="sorter">
                                    <FaArrowsAltV />
                                </div>
                                <div className="idx">{idx + 1}</div>
                            </div>
                            <div className="title-and-status">
                                <div className="left">
                                <div className="title">{question.title}
                                </div>
                                {question.sentAt && <div className="sent-at">{getFriendlyTime(new Date(question.sentAt))}</div>}
                                </div>
                                
                                {!didUserAnswerQuestion && <div className="status">
                                    <Button variant={BUTTON_VARIANT_SECONDARY}>START WRITING</Button>
                                </div>}
                                {didUserAnswerQuestion && <div className="status">
                                    <Button variant={BUTTON_VARIANT_SECONDARY} onClick={() => editAnswer(question)}>EDIT</Button>
                                </div>}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default StoryList;