import { Divider, Form } from "antd";
import Button from "../../../../components/forms/Button";
import Select from "../../../../components/forms/Select";
import { BOOK_PRICE_OPTIONS } from "../../../../constants/pricing";
import { isEmailValid, } from "../../../../services/textService";
import "./index.scss";
import Input from "../../../../components/forms/Input";
import classNames from "classnames";
import DatePicker from "../../../../components/forms/DatePicker";
import { STEP_PERSONALIZE } from "../../constants";
import TextArea from "../../../../components/forms/TextArea";

export default props => {
    const { params, } = props;
    const isDisabled = !params.recipientFirstName || !params.recipientLastName || !isEmailValid(params.recipientEmail) || !params.senderName || !params.senderMessage
    const emailStatus = !params.recipientEmail || isEmailValid(params.recipientEmail)
    const onChange = (key, value) => {
        props.onChange(key, value)
    }
    return (
        <div className="gift-step">
            <div className="progress-bar">
                <div className="progress-bar-item clickable" onClick={() => props.onChange('step', STEP_PERSONALIZE)}>
                    <div className="step-text">PERSONALIZE</div>
                    <div className="step-bar" />
                </div>
                <div className="progress-bar-item">
                    <div className="step-text">DELIVERY</div>
                    <div className="step-bar" />
                </div>
                <div className="progress-bar-item empty">
                    <div className="step-text">BILLING</div>
                    <div className="step-bar" />
                </div>
            </div>

            <div className="form-wrapper">
                <h3>Gift delivery details
                </h3>
                <Form.Item label="Number of books">
                    <Select options={BOOK_PRICE_OPTIONS} value={params.numOfBooks} onChange={newValue => onChange('numOfBooks', newValue)} />
                </Form.Item>
                {/* FULL NAME */}
                <div className="row name-wrapper">
                    <Form.Item label="Your gift recipient's first name">
                        <Input onChange={e => onChange('recipientFirstName', e.target.value)} value={params.recipientFirstName}
                            placeholder="First name"
                        />
                    </Form.Item>
                    <Form.Item label="Your gift recipient's last name">
                        <Input onChange={e => onChange('recipientLastName', e.target.value)} value={params.recipientLastName}
                            placeholder="Last name"
                        />
                    </Form.Item>
                </div>
                {/* EMAIL */}
                <Form.Item className={classNames("email", { "input-invalid": !emailStatus })} label="Your gift recepient's email" help={emailStatus ? "" : "Please provide a valid email address"}>
                    <Input onChange={e => onChange('recipientEmail', e.target.value)} value={params.recipientEmail}
                        placeholder="example@example.com"
                    />
                </Form.Item>

                <Form.Item label="Send my gift on">
                    <DatePicker value={params.sendGiftAt} onChange={e => onChange('sendGiftAt', e)} />
                </Form.Item>
                <Divider />
                <h3>Add a gift message</h3>
                {/* TODO: From input */}
                <Form.Item label="From">
                    <Input placeholder="Your name + anyone else the gift is from!"
                        value={params.senderName} onChange={e => onChange('senderName', e.target.value)}
                    />
                </Form.Item>
                <Form.Item label="Your message">
                    <TextArea value={params.senderMessage} onChange={e => onChange('senderMessage', e.target.value)} />
                </Form.Item>
                {/* TODO: Your message input */}
                <Button disabled={isDisabled} onClick={props.onSubmit}>CONTINUE</Button>
            </div>
        </div>
    )
}