export const BASE_PRICE = 99;
export const BOOKS_PRICE_MAP = {
    1: 0,
    2: 38,
    3: 78,
    4: 117,
    5: 156,
    6: 195,
    7: 234,
    8: 273,
    9: 312,
    10: 351,
    11: 390,
}

export const BOOK_PRICE_OPTIONS = Object.keys(BOOKS_PRICE_MAP).map(numOfBooks => {
    let label = `${numOfBooks} `
    if (numOfBooks === 1) {
        label += "(included in subscription)"
    }
    else {
        label += `(${numOfBooks - 1} extra book${numOfBooks > 2 ? 's' : ''}) +$${BOOKS_PRICE_MAP[numOfBooks]}`
    }


    return { label, value: parseInt(numOfBooks) }
})