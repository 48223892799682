import ReactCodeInput from 'react-verification-code-input';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { postRequest } from '../../../../services/requestService'
import { getRedirectUrl } from '../../../../services/clientService';
import { FaGoogle } from '@react-icons/all-files/fa/FaGoogle'
import { FaMicrosoft } from '@react-icons/all-files/fa/FaMicrosoft'
import "./index.scss";

export default props => {
    const { loading, setLoading, params, onChange, setParams } = props;


    return (
        <div className="set-code-step">
            <h1>Check your email!</h1>
            <div className="instructions">{`To login password-free, tap the button in the email we sent to ${params.email}.`}</div>



            <br />
            <div>{`Wrong email address? Please `}<span
                className="edit-btn"
                onClick={() => setParams({ ...params, step: 0, email: '' })}>re-enter email address</span></div>

            <div className="links">
                <a className="link-item" href="https://mail.google.com" target="_blank">
                    <FaGoogle />
                    <div>Open Gmail</div>
                </a>

                <a className="link-item" href="https://outlook.live.com" target="_blank">
                    <FaMicrosoft />
                    <div>Open Outlook</div>
                </a>
            </div>
        </div>
    )
}