import { useSelector } from "react-redux";
import Page from "../../components/layout/Page";
import DashboardActions from "./Components/DashboardActions";
import StoryList from "./Components/StoryList";

const DashboardPage = props => {
    const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
    return (
        <Page fullWidth={true}>
            <DashboardActions userDetails={userDetails} />
            <div className="inner">
                <StoryList userDetails={userDetails} />
            </div>
        </Page>
    )
}

export default DashboardPage;