import axios from 'axios';

import { BASE_URL, isDev, removeCookie } from './clientService.js';
import { COOKIE_NAME_AUTH_TOKEN, removeAuthToken } from './authService.js';

const Axios = axios.create({
    withCredentials: true,
    crossdomain: true,
});
axios.defaults.preflightContinue = true;

Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Axios.interceptors.request.use(async (req) => {
    try {

        req.headers['Access-Control-Allow-Origin'] = window.location.origin;
    } catch (e) {
        console.log(e);
    }
    return req;
});

Axios.interceptors.response.use(
    async (res) => {
        try {

        } catch (e) {
            console.log(e);
        }
        return res;
    },
    (error) => {
        if (error?.response?.status === 401) {
            removeCookie(COOKIE_NAME_AUTH_TOKEN)
            if (isDev()) {
                console.log('Unauthorized');
            }
            // window.location.href = '/login';
        }
        return Promise.reject(error);
    },
);

export const deleteRequest = (relativeUrl, params = {}) => {
    const requestUrl = `${BASE_URL}${relativeUrl}`;
    return Axios.delete(requestUrl, params, {
        headers: {
            Accept: 'application/json',
        },
    });
};

export const postRequest = (relativeUrl, params = {}) => {
    const requestUrl = `${BASE_URL}/${relativeUrl}`;
    return Axios.post(requestUrl, params, {
        headers: {
            Accept: 'application/json',
        },
    });
};

export const getRequest = (relativeUrl, params = {}, config = {}) => {
    const requestUrl = `${BASE_URL}/${relativeUrl}`;
    return Axios.get(requestUrl, {
        params,
        headers: {
            'Content-Type': 'application/json',
        },
        mode: 'cors',
        ...config,
    });
};


export const fileRequest = (relativeUrl, file) => {
    const requestUrl = `${BASE_URL}${relativeUrl}`;
    const formData = new FormData();
    formData.append('file', file)
    return Axios.post(requestUrl, formData, {
        headers: {
            Accept: 'multipart/form-data',
        },
    });
};

