import { Form } from "antd";
import Select from "../../../../components/forms/Select";
import { BOOK_PRICE_OPTIONS } from "../../../../constants/pricing";
import { isEmailValid, } from "../../../../services/textService";
import "./index.scss";
import Input from "../../../../components/forms/Input";
import classNames from "classnames";
import Alert from "../../../../components/forms/Alert";
import Checkbox from "../../../../components/forms/Checkbox";
import { STEP_PERSONALIZE } from "../../constants";
import Button from "../../../../components/forms/Button";
import { postRequest } from "../../../../services/requestService";
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useState } from "react";

const OrderStep = props => {
    const { params, } = props;
    const emailStatus = !params.email || isEmailValid(params.email)
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const onChange = (key, value) => {
        props.onChange(key, value)
    }
    const onPayClick = async () => {
        setLoading(true)
        await postRequest('auth/complete-signup', params)
        
        dispatch(push('/'))
        setLoading(false)
    }
    return (
        <div className="order-step">
            <div className="progress-bar">
                <div className="progress-bar-item clickable" onClick={() => props.onChange('step', STEP_PERSONALIZE)}>
                    <div className="step-text">PERSONALIZE</div>
                    <div className="step-bar" />
                </div>
                <div className="progress-bar-item">
                    <div className="step-text">BILLING</div>
                    <div className="step-bar" />
                </div>
            </div>
            <div className="form-wrapper">
                <h3>Purchase Storyworth</h3>
                <Form.Item label="Number of books">
                    <Select options={BOOK_PRICE_OPTIONS} value={params.numOfBooks} onChange={newValue => onChange('numOfBooks', newValue)} />
                </Form.Item>
                {/* FULL NAME */}
                <div className="row name-wrapper">
                    <Form.Item label="Your first name">
                        <Input onChange={e => onChange('firstName', e.target.value)} value={params.firstName}
                            placeholder="First name"
                        />
                    </Form.Item >
                    <Form.Item label="Your last name">
                        <Input onChange={e => onChange('lastName', e.target.value)} value={params.lastName}
                            placeholder="Last name"
                        />
                    </Form.Item>
                </div>
                {/* EMAIL */}
                <Form.Item className={classNames("email", { "input-invalid": !emailStatus })} label="Your email address" help={emailStatus ? "" : "Please provide a valid email address"}>
                    <Input onChange={e => onChange('email', e.target.value)} value={params.email}
                        placeholder="example@example.com"
                    />
                </Form.Item>
                {/* DISCLAIMER */}
                <Checkbox className="disclaimer">I would like to get story writing tips, inspiration, customer stories, and promotions via email</Checkbox>

                <Alert>
                    Privacy is important to us. By purchasing, you acknowledge that you have read and accept Storyworth's <a href="/terms" target="_blank">terms of service</a> and <a href="/privacy" target="_blank">privacy policy</a>.
                </Alert>
                {/* CHECKOUT */}

                <br /><br />
                <Button loading={loading} onClick={onPayClick}>Pay Now</Button>
            </div>

        </div>
    )
}

export default OrderStep;