import * as types from './types';

const initialState = {
  userDetails: undefined,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SET_USER_DETAILS:
      return {
        ...state,
        ...payload,
      };
  
    default:
      return state;
  }
}
