import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import App from './App.js';
import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import { Theme } from './utils/theme.js';
import createStore, { history } from './store';
const createdStore = createStore(history);
ReactDOM.render(
  <Provider store={createdStore}>
    <ConfigProvider theme={{ token: Theme() }}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);